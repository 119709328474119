<template>
  <base-material-card
    color="accent"
    icon="mdi-text-box-check"
    inline
    class="px-5 py-3 mt-7"
    style="width: 100%"
  >
    <v-card :loading="isLoading()">
      <v-card-title>
        <v-text-field
          v-model="contractNumber"
          color="secondary"
          append-icon="mdi-magnify"
          :success="contractNumberSuccess"
          :error-messages="contractNumberErrors"
          name="contractNumber"
          label="Contract Number"
          type="text"
          class="input-max-width"
          @keyup.enter="searchContract"
          @click:append="searchContract"
        />
      </v-card-title>
      <v-expand-transition>
        <div v-if="contract && contract.contractNumber">
          <v-card-text>
            <p class="text-h5 text--primary">
              {{ contract.contractName }}
              <v-subheader style="height: unset;">
                {{ contract.contractNumber }}
              </v-subheader>
            </p>
            <v-container
              fluid
              grid
              class="pa-0"
            >
              <LabelValue
                label="Account Name"
                :value="contract.accountName"
              />
              <LabelValue
                label="Contract State"
                :value="contract.contractStateDisplay"
              />
              <LabelValue
                label="Package"
                :value="contract.packageName"
              />
              <LabelValue
                label="Sales Rep"
                :value="contract.ownerName"
              />
              <LabelValue
                label="Created On"
                :value="contract.createdOn | displayDateFormat"
              />
              <LabelValue
                label="Expires On"
                :value="contract.expiresOn | displayDateFormat"
              />
              <LabelValue
                label="Opportunity Name"
                :value="contract.opportunityName"
              />
              <LabelValue
                label="First User"
                :value="contract.contactName"
              />
              <LabelValue
                label="First User Email/Account"
                :value="contract.contactEmail"
              />
              <LabelValue
                label="First User Phone"
                :value="contract.phoneNumber | phone"
              />
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>File Types</v-list-item-subtitle>
                  <v-list-item-title
                    v-for="fileTypeItem in contract.fileTypeLabelsOnContract"
                    :key="fileTypeItem"
                  >
                    {{ fileTypeItem }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-container>
            <v-divider />
            <v-row no-gutters>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Product
                      </th>
                      <th class="text-left">
                        Quantity
                      </th>
                      <th class="text-left">
                        Commitment
                      </th>
                      <th class="text-left">
                        File Type
                      </th>
                      <th class="text-left">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in contract.contractLines"
                      :key="item.id"
                    >
                      <td>{{ item.productName }}</td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.commitment }}</td>
                      <td>{{ item.fileTypeLabel }}</td>
                      <td>{{ item.chargeRate | money }} {{ item.chargeType }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <p
              v-if="contract.hasBeenOnboarded"
              class="red--text font-weight-bold mb-0 mr-2"
            >
              Contract already onboarded.
            </p>
            <p
              v-if="contract.packageId === '00000000-0000-0000-0000-000000000000'"
              class="red--text font-weight-bold mb-0 mr-2"
            >
              No subscription package assigned to contract.
            </p>
            <v-btn
              color="success"
              :disabled="disableActivation"
              :loading="isLoading()"
              @click="activateContract"
            >
              Activate Contract
            </v-btn>
          </v-card-actions>
        </div>
        <v-skeleton-loader
          v-else-if="isLoading()"
          type="article, actions"
        />
      </v-expand-transition>
    </v-card>
  </base-material-card>
</template>

<script>
import {
  SET_LOADING_MESSAGE,
  SET_GLOBAL_SNACKBAR
} from '@/store/mutation-types'
import { mapMutations, mapGetters } from 'vuex'
import { tenantService } from '@/shared/services'

export default {
  name: 'ContractActivation',

  components: {
    LabelValue: () => import('@/components/custom/LabelValue')
  },

  data: () => ({
    contractNumber: null,
    contractNumberErrors: null,
    contractNumberSuccess: null,
    contract: null
  }),

  computed: {
    ...mapGetters(['isLoading']),
    disableActivation () {
      return this.contract.hasBeenOnboarded || this.contract.packageId === '00000000-0000-0000-0000-000000000000'
    }
  },

  created () {},

  methods: {
    ...mapMutations([SET_GLOBAL_SNACKBAR]),
    async searchContract () {
      // this.setLoadingMessage("random");
      let resp = await tenantService.getContract(this.contractNumber)
      if (resp.errorResponse) {
        this.contractNumberErrors = resp.errorResponse
      } else {
        this.contract = resp
        this.contractNumberSuccess = true
        this.contractNumberErrors = null
      }
    },
    async activateContract () {
      this.$store.commit('setLoadingMessage', 'random')
      let resp = await tenantService.activateContract(this.contractNumber)
      if (resp.errorResponse) {
        this.contractNumberErrors = resp.errorResponse
      } else {
        this.contractNumberSuccess = null
        this.contractNumberErrors = null
        this.setGlobalSnackbar({
          message: `Contract Activated for ${this.contract.accountName}!`,
          color: 'success'
        })
        this.contractNumber = null
        this.contract = null
      }
    }
  }
}
</script>
