var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-material-card",
    {
      staticClass: "px-5 py-3 mt-7",
      staticStyle: { width: "100%" },
      attrs: { color: "accent", icon: "mdi-text-box-check", inline: "" }
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.isLoading() } },
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "input-max-width",
                attrs: {
                  color: "secondary",
                  "append-icon": "mdi-magnify",
                  success: _vm.contractNumberSuccess,
                  "error-messages": _vm.contractNumberErrors,
                  name: "contractNumber",
                  label: "Contract Number",
                  type: "text"
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchContract.apply(null, arguments)
                  },
                  "click:append": _vm.searchContract
                },
                model: {
                  value: _vm.contractNumber,
                  callback: function($$v) {
                    _vm.contractNumber = $$v
                  },
                  expression: "contractNumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-expand-transition",
            [
              _vm.contract && _vm.contract.contractNumber
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "p",
                            { staticClass: "text-h5 text--primary" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.contract.contractName) +
                                  "\n            "
                              ),
                              _c(
                                "v-subheader",
                                { staticStyle: { height: "unset" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.contract.contractNumber) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-container",
                            {
                              staticClass: "pa-0",
                              attrs: { fluid: "", grid: "" }
                            },
                            [
                              _c("LabelValue", {
                                attrs: {
                                  label: "Account Name",
                                  value: _vm.contract.accountName
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Contract State",
                                  value: _vm.contract.contractStateDisplay
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Package",
                                  value: _vm.contract.packageName
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Sales Rep",
                                  value: _vm.contract.ownerName
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Created On",
                                  value: _vm._f("displayDateFormat")(
                                    _vm.contract.createdOn
                                  )
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Expires On",
                                  value: _vm._f("displayDateFormat")(
                                    _vm.contract.expiresOn
                                  )
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "Opportunity Name",
                                  value: _vm.contract.opportunityName
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "First User",
                                  value: _vm.contract.contactName
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "First User Email/Account",
                                  value: _vm.contract.contactEmail
                                }
                              }),
                              _c("LabelValue", {
                                attrs: {
                                  label: "First User Phone",
                                  value: _vm._f("phone")(
                                    _vm.contract.phoneNumber
                                  )
                                }
                              }),
                              _c(
                                "v-list-item",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-subtitle", [
                                        _vm._v("File Types")
                                      ]),
                                      _vm._l(
                                        _vm.contract.fileTypeLabelsOnContract,
                                        function(fileTypeItem) {
                                          return _c(
                                            "v-list-item-title",
                                            { key: fileTypeItem },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(fileTypeItem) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-simple-table", {
                                attrs: { dense: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function() {
                                        return [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                      Product\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                      Quantity\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                      Commitment\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                      File Type\n                    "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [
                                                  _vm._v(
                                                    "\n                      Price\n                    "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.contract.contractLines,
                                              function(item) {
                                                return _c(
                                                  "tr",
                                                  { key: item.id },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.productName)
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(_vm._s(item.qty))
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(item.commitment)
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.fileTypeLabel
                                                        )
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("money")(
                                                            item.chargeRate
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            item.chargeType
                                                          )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3099468238
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm.contract.hasBeenOnboarded
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "red--text font-weight-bold mb-0 mr-2"
                                },
                                [
                                  _vm._v(
                                    "\n            Contract already onboarded.\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.contract.packageId ===
                          "00000000-0000-0000-0000-000000000000"
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "red--text font-weight-bold mb-0 mr-2"
                                },
                                [
                                  _vm._v(
                                    "\n            No subscription package assigned to contract.\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                disabled: _vm.disableActivation,
                                loading: _vm.isLoading()
                              },
                              on: { click: _vm.activateContract }
                            },
                            [
                              _vm._v(
                                "\n            Activate Contract\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.isLoading()
                ? _c("v-skeleton-loader", {
                    attrs: { type: "article, actions" }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }